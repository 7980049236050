import React from 'react';
import styled from 'styled-components';
import {Spin} from 'antd';

import * as AppContext from '../../AppContext';
import ActivityDetailDisplay from '../../Components/Activity.DisplayDetail';
import Footer from './Footer';

const queryString = require('query-string');

function PublicPage(props) {
  const app = React.useContext(AppContext.Context);
  const {location} = props;
  const params = queryString.parse(location.search) || {};

  const [record, setRecord] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const fetchActivityDetailById = React.useCallback(
    async (id) => {
      setLoading(true);
      try {
        setRecord(await app.actions.fetchActivityById(id));
      } catch (err) {
        console.warn(err);
      }
      setLoading(false);
    },
    [app.actions],
  );

  React.useEffect(() => {
    fetchActivityDetailById(params.id);
  }, [fetchActivityDetailById, params.id]);

  return (
    <Wrapper>
      <div className="content">
        {loading ? <Spin /> : <ActivityDetailDisplay record={record} />}
      </div>

      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: space-between;

  & > .content {
    max-width: 1100px;
    width: 100%;
    min-height: 300px;
    margin: 40px auto;
    padding: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
`;

export default PublicPage;
